.feeTypeSelect {
  margin: 0;
  margin-bottom: 20px;
  padding: 5px 10px;

  width: 300px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #676770;
  border-left: none;
}

.fieldName {
  margin: 0;
  margin-right: 25px;
  padding: 0;

  color: #676770;
  font-size: 16px;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 5px;
  margin-right: 55px;
  padding: 0;
}

.inputValueTypeSecond {
  position: relative;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;
  padding-right: 5px;

  top: center;
}

.doubleInputSecond {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 25px;


  width: 75px;
  font-size: 16px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInputSecond::placeholder {
  color: rgba(77, 182, 172, .5);
}


.eofmiles {
  font-size: 16px;
  color: #676770;
  z-index: 10;
}

.flexdiv {
  display: flex;
  border-bottom:1px solid rgba(112,112,112, 0.5);
  margin-bottom: 10px;
}

.chargesPerMileContainer {
  display: flex;
  margin-bottom: 25px;
  position: relative;
  padding-bottom:10px ;
  border-bottom:1px solid rgba(112,112,112, 0.5);
}

.chargesPerMileInput {
  position: relative;
  margin: 0;
  margin-left: 5px;
  padding: 0;

  width: 40px;
  font-size: 16px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}