.container {
  grid-area: resultBox;
  margin: 0;
  padding-top: 10px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 10px; /* padding-left: 30px; */
}

.title {
  margin: 0;
  padding: 0;

  font-size: 18px;
  font-weight: 600;
  text-align: center;
/*   width: 650px; */
}

.selectedInfo {
  display: flex; 
 margin-top: 10px;
 margin-right: auto;
 margin-left: auto;
 margin-bottom: 10px;
 padding: 0;
 /* text-align: center; */
 align-items: center;
 justify-content: center
}

.departmentName {
  margin: 0 20px 0 0;

  color: #4db6ac;
  font-size: 16px;
  text-transform: capitalize;
}

.rateName {
  margin: 0;
  padding: 0;
  color: red;
  font-size: 16px;
  text-transform: capitalize;
}

.secondRateName{
  margin: 0;
  margin-left: 5px;
  padding: 0;
  color: red;
  font-size: 16px;
  text-transform: capitalize;
}

.oldApplyNew {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calculationNumber {
  margin: 0 20px 0 0;

  color: #707070;
  font-size: 16px;
  text-transform: capitalize;
  /* width:400px; */
  text-align: center;
}
