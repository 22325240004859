.tagsContainer {
  position: relative;
  /*   padding: 5px; */
  display: flex;
  flex-direction: column;
}

.tagNameInp {
}

.buttonContainer {
  display: inline-block;
  margin-right: 15px;
}

.removeExtraBtn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.removeExtraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: red;
}

.extraBtn {
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #ccc;
  margin: 10px auto;
  padding: 0;
  text-transform: uppercase;
  border: none;
  background-color: inherit;
}

.extraBtn svg {
  margin-right: 5px;
  width: 30px;
  height: 30px;
  fill: #4db6ac;
}

.overlay {
  background-color: white;
  position: fixed;
  top: 100px;
  z-index: 5;
  width: 610px;
  height: 80%;
  overflow: auto;
}

.close {
  position: absolute;
  top: 3px;
  right: 13px;
  border: none;
  background-color: transparent;
  filter: alpha(opacity=20);
  opacity: 1 !important;
}

.close span {
  font-size: 20px;
  color: #000 !important;
  cursor: pointer;
}

.fieldset {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0px;
  /*  */
  padding: 0px;
  padding-top: 5px; /* 30px */
  padding-left: 10px;
  padding-right: auto;
  font-size: 16px;
  border: none;
}

.label {
  margin: 0;
  padding: 0;
  padding-right: 0px;

  white-space: nowrap;
}

.input {
  margin: 0;
  margin-left: 5px;
  padding-top: 6px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #5ebdb4;
  border-left: none;
  width: 150px;

  font-family: Arial;
  color: #707070;
  font-weight: 600;
}

.input::placeholder {
  margin: 0;
  padding: 0;
  color: rgba(77, 182, 172, 0.5);
  text-align: center;
  text-align-last: center;
}

.select {
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-right: 15px;
  text-align-last: center;
  width: 170px;
  border: 2px solid #707070;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #5ebdb4;
  border-left: none;
  color: #707070;
  font-weight: 600;
}

.hLine {
  margin: 0;
  margin-left: -20px;
  padding: 0;
  margin-top: 10px;

  width: 600px;
  border-top: 1px solid #ccc;
  margin-bottom: 5px;
  /*  background-color: #4DB6AC;  */
}

.iconContainer {
  margin: 0;
  margin-right: 5px;
  padding: 4px;

  width: 35px;
  height: 35px;

  background-color: #4db6ac;
  border-radius: 50%;
}

.iconContainer svg {
  width: 27px;
  height: 27px;

  fill: #ffffff;
}

.sideBarButton {
  position: absolute;
  right: -45px;
  top: 88px;
  border: none;
  color: white;
  background-color: transparent;
  z-index: 100;
}

.showSideBar {
  background: rgb(237, 244, 247);
  position: fixed;
  top: 0;
  left: 0;
  width: 620px;
  height: 100%;
  padding: 20px 0;
  transition: all 0.5s ease;
  z-index: 4;
  padding: 5px;
}

.hideSideBar {
  background: rgb(237, 244, 247);
  position: fixed;
  top: 0;
  left: -655px;
  width: 610px;
  height: 100%;
  padding: 20px 0;
  transition: all 0.5s ease;
  z-index: 4;
}

.fixedContainer {
  position: sticky;
  top: 0;
  background: white;
  z-index: 4;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  width: 100%;
  padding: 2px;
}
