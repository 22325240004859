.btn {
    color: #676770;
    background-color: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;

    padding: 5px 10px;
  
    border: 1px solid #30ad64;
    border-radius: 5px;
  
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .btn:hover {
    color: #ffffff;
    background-color: #4db6ac;
  }
  