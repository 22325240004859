.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  margin-top: 7px;
  border-bottom: 1px solid rgba(112,112,112, 0.5);
}

.inputName {
  margin: 0;
  margin-right: 85px;
  margin-bottom: 10px;
  padding: 0;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.radiobtnContainer {
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  display: flex;;
}
.radio
{
 margin-left: 35px;
}