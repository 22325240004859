.container {
  position: relative;
  display: flex;
  justify-content: space-between;
 /*  margin-left: 350px; */
  padding-top: 15px;
  padding-right: 0px;
  padding-bottom: 5x;
  padding-left: 0px;
 /* width: 100%;
   min-width: 320px;
  max-width: 940px; */
}

.title {
  position:relative;
  width: 500px;
  left:30px;
  margin: 0;
  padding: 0;

  color: #30ad64;
  font-size: calc(14px + (100vw - 320px) / 40.72);
  font-weight: 600;
  letter-spacing: 4px;
  line-height: 1.2;
  text-transform: uppercase;
  white-space: nowrap;

 /*  width: 70%; */
}

.mobileBtn {
  align-self: center;
  margin: 0;
  padding: 0;

  border-top: 2px solid #30ad64;
  border-right: none;
  border-bottom: 2px solid #30ad64;
  border-left: none;

  background-color: inherit;

  width: 30px;
  height: 12px;

  cursor: pointer;
}

.mobileBtn:hover {
  border-top: 2px solid #9ec6af;
  border-bottom: 2px solid #9ec6af;
}

@media(min-width: 768px) {
  .container {
    padding-top: 5px;
    padding-left: 0px;
  }

  .title {
    font-size: 25px;
    width: 393px;
  }

}

@media(min-width: 1155px) {
  .container {
    padding-right: 0;
    padding-left: 0;
  }
  .mobileBtn {
    display: none;
  }
}
@media(max-width: 1150px) {
  .container {
    margin: 0 auto;
    margin-left: 10px;
  }
  .title
  {
    position: static;
     left:10px; 
    
  }
}
/*   @media(min-width: 1920px) {
  .container {
    
    width: 2020px;
  }
}   */