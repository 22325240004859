.container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  
}

.iconContainer {
  margin: 0;
  margin-right: 20px;
  padding-top: 3px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 3px;

  width: 30px;
  height: 30px;

  background-color: #30ad64;
  border: 3px solid #ffffff;
  border-radius: 50%;
 /* float:left;*/
}
.iconContainer svg {
  width: 20px;
  height: 20px;
}

.userName {
  margin: 0;
  padding: 0;

  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
}

@media(min-width: 868px) {
  .iconContainer {
/*     padding-top: 7px;
    padding-left: 7px; */
    width: 30px;
    height: 30px;

    border-width: 2px;
  }

  .iconContainer svg {
    width: 20px;
    height: 20px;
  }

  .userName {
    font-size: 24px;
    color: #ffffff;
  }
}
