.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding-bottom: 10px;
  flex-wrap: wrap;
 /*  justify-content: space-between; */
 /*  border-bottom:1px solid rgba(112,112,112, 0.5);
  border-top:1px solid rgba(112,112,112, 0.5); */
}

.inputName {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.doubleInput {
  position: relative;
  margin: 0;
  margin-left: 115px;
  padding: 0;
  padding-left: 30px;

  width: 90px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInputSecond {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  

  width: 75px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInput::placeholder,
.doubleInputSecond::placeholder {
  margin: 0;
  padding: 0;

  color: rgba(77, 182, 172, .5);
  font-size: 16px;
}

.inputValueType {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 145px;
  top: center;
}

.inputValueTypeSecond {
  position: relative;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  width: 60px;
  top: center;
}

.eofmiles {
  font-size: 16px;
  color: #676770;
  z-index: 10;
}