.form {
  margin: 0 auto;
  padding: 0;
  padding-bottom: 70px;
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 940px;
  border: 1px solid rgba(77, 182, 172, .5);
  /*   overflow: auto; */
}

.extraBtn {
  display: flex;
  align-items: center;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: 50px;
  padding: 0;

  border: none;
  background-color: inherit;
}

.extraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: #4db6ac;
}

.input {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 25px;
  width: 50px;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.nameInput {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 25px;

  width: 100px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.inputName {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  /*  width: 173px; */

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.title1 {
  margin: 0;
  margin-right: 10px;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 5px;
  padding-left: 5px;
  color: #676770;
  font-size: 24px;
  text-transform: uppercase;

}

.vl {
  border-left: 1.5px solid #e1e1e1;
  height: 25px;
  margin-left: 20px;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  margin-left: 20px;
  margin-top: 5px;
  /*  width: 320px; */
}

.formHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0 25px;
  padding-top: 30px;
  padding-right: 0;
  padding-bottom: 25px;
  padding-left: 0;

  border: none;
}

.fieldName {
  margin: 0;
  margin-right: 20px;
  margin-left: 10px;
  padding: 0;

  color: #676770;
  font-size: 16px;
}


.inputGroup {
  margin: 0;
  padding: 0;

  border-top: 1px dotted #676770;
  border-right: none;
  border-bottom: none;
  border-left: none;
}

.rateName {
  margin: 0;
  margin-bottom: 25px;
  margin-left: 30px;
  padding: 0 10px;

  color: 676770;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}

.rateSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}


.inputName {
  margin: 0;
  margin-right: 5px;
  padding: 0;



  color: #676770;
  font-size: 16px;
  text-align: right;
}

.removeExtraBtnList {
  display: flex;
  align-items: center;
  margin-right: 0;
  padding: 0;
  border: none;
  background-color: inherit;
}

.removeExtraBtnList svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: red;
}

.doubleInput {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 30px;

  width: 90px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInputSecond {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 45px;

  width: 120px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.input::placeholder,
.doubleInput::placeholder,
.doubleInputSecond::placeholder {
  margin: 0;
  padding: 0;
  color: rgba(77, 182, 172, .5);
  font-size: 16px;
}

.inputValueType {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 182px;
  top: center;
}

.inputValueTypeSecond {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 265px;
  top: center;
}

.radiobtnContainer {
  margin: 0;
  padding: 0;
}

.shuttleContainer {
  display: flex;
  justify-content: space-between;
}

.shuttleContainer .inputContainer {
  width: fit-content;
}

.allPage {
  padding: 20px 50px;
  border: 2px solid rgba(77, 182, 172, .5)
}

.select {
  margin: 0;
  margin-bottom: 5px;
  margin-right: 6px;
  padding: 0;

  width: 95px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  text-align-last: center;
  color: #707070;
  font-weight: 600;
}

.label {
  padding: 5px;
}