.container {
  position: relative;
  margin-top: 0;
  margin-right: 20px;
  margin-bottom: 30px;
  margin-left: 20px;
  padding: 0;

  max-width: 280px;
}

.select {
  margin: 0;
  font-size: 14px;
  letter-spacing: 2px;
  padding-top: 7px;
  padding-left: 20px;
  padding-bottom: 5px;
  color: #4db6ac;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #676770;
  border-left: none;

  /*  width: 280px; */
}

.select>option {
  color: rgba(77, 182, 172, .5);
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: capitalize;
}

.input:hover {
  border-bottom: 1px solid #4db6ac;
}

.input:hover::placeholder {
  color: #4db6ac;
}

.input:focus {
  outline-color: #30ad64;
}

.submitBtn {
  position: absolute;
  margin: 0;
  padding: 0;

  cursor: pointer;

  top: 0;
  right: 0;

  background-color: inherit;
  border: none;
}