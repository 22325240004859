.container {
  grid-area: oneDayPacking;

  margin: 0;
  padding-top: 10px;
  padding-right: 5px;
  padding-bottom: 0;
  padding-left: 5px;

  border-top: 1px solid #4db6ac;
  /* border-bottom: 1px solid #4DB6AC; */
}

.unpSepCont {
  margin: 0;
  margin-left: -20px;
  padding: 0;

  width: 580px;
}
.unpSepCont1 {
  margin: 0;
  margin-left: 20px;
  padding: 0;
}
.groupInput {
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  padding: 0;
  border: none;

  /*   border-bottom: 1px solid #4DB6AC; */
}

.groupInputEO {

  margin: 0;

  padding: 0;
  border: none;
  width: 600px;
  /*   border-bottom: 1px solid #4DB6AC; */
}

.dayTitle {
  margin: 5px;
  margin-left: 20px;
  margin-right: 50px;
  width: 500px;
}
.divide {
  margin-left: 20px;
  display: flex;
  white-space: nowrap;
}

.decorateContainer {
  margin: 0;
  padding: 0;
  padding-top: 5px;

  width: 100%;

  border-top: 1px solid #4db6ac;
}

.groupInput input {
  margin: 0;
  /* margin-top: 2px; */

  margin-right: 10px;
  padding: 0;

  width: 25px;
  height: 17px;

  text-align: center;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  color: #707070;
  font-weight: 600;
}

.inputContainer {

  margin-right: 15px;

  margin-bottom: 5px;
  padding: 0;
}

.select {
  margin: 0;

  margin-left: 5px;
  margin-right: 80px;
  padding: 0;
  padding-left: 10px;

  width: 110px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  color: #707070;
  font-weight: 600;
}

.warning {
  margin: 0;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-right: 400px;
  padding: 0;

  color: red;
}
.warning1 {
  margin: 0;
  margin-bottom: 5px;
  margin-right: 200px;
  padding: 0;
  white-space: nowrap;
  color: red;
}
.commonLabel {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  white-space: nowrap;
}
.label {
  white-space: nowrap;
}
.commonInput {
  margin: 0;
  margin-bottom: 5px;
  margin-left: 5px;

  padding: 0;
  padding-right: 5px;
  padding-left: 5px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  margin-right: 15px;
  text-align-last: center;
  color: #707070;
  font-weight: 600;
}

.commonInput::placeholder {
  color: rgba(77, 182, 172, 0.5);
}
.checkbox {
  margin: 0;
  padding: 0;
}

.hLine {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #4db6ac;
  margin: 0 0;
  padding: 0;
}

.vl {
  border-left: 1.5px solid #e1e1e1;
  height: 25px;
  margin-left: 15px;
  margin-right: -30px;
}

.dateInput {
  width: 110px !important;
}

.btnSubmit {
  position: relative;
  left: -25%;
  top: -25px;
  float: right;
  z-index: 0;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: #4db6ac;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
}

.up {
  margin-top: 10px;
  border: solid #4db6ac;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  border: solid #4db6ac;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
