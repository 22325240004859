.container {
  margin: 0;
  margin-top: 5px;
  padding: 5px 0;
  border-top: 1px solid #707070;
  border-right: 0 ;
  border-bottom: 0;
  border-left: 0;
}

.title {
  margin: 0;
  padding: 0 5px;

  text-align: center;
  font-weight: 700;
}

.commonLabel {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
}

.commonInput {
  margin: 0;
  margin-left: 5px;
  margin-right: 20px;
  padding: 0;
  padding-right: 5px;
  padding-left: 5px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  text-align-last:center;
  width: 85px;
  color:#707070 ;
  font-weight: 600;
}

.commonInput::placeholder {
  color:rgba(77, 182, 172, .5);
}
