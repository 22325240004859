.title {
  margin: 0;
 margin-bottom: 10px; 
 margin-top: 20px;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 5px;
  border-bottom: 1.5px solid black;
  color: #676770;
  font-size: 24px;
  text-transform: uppercase;

  
}