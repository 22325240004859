.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

  /* 383 500 for sperate component*/
}

.inputName {
  margin: 0;
  margin-right: 5px;
  margin-left: 5px;
  flex-wrap: nowrap;

  padding: 0;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.input {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 25px;

  width: 100px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.inputLongPadding {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 65px;

  width: 200px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.input::placeholder,
.inputLongPadding::placeholder {
  margin: 0;
  padding: 0;

  color: rgba(77, 182, 172, .5);
  font-size: 16px;
}

.inputValueType {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 45px;
  top: center;
}

.inputValueTypeHrs {
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 130px;
  top: center;
}