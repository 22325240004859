.mainSection{
width: 600px;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.containerSecond {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.buttonContainer {
  display: inline-block;
}

.shuttleContainer {
  display: flex;
  justify-content: space-between;
}

.shuttleContainer .optionsContainer {
  width: fit-content;
}

.optionsContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;


}

.radiobtnContainer {
  margin: 0;
  padding: 0;
}


.inputName {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  width: 173px;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.inputNameSecond {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  width: 60px;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.inputNameThird {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  width: 60px;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

  width: 200px;
}

.inputContainerSecond {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

  width: 120px;
}

.inputContainerThird {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

  width: 150px;
}

.input {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 5px;

  width: 50px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}



.inputSecond {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 5px;

  width: 50px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.inputThird {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 20px;

  width: 100px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.input::placeholder, .inputSecond::placeholder, .inputThird::placeholder {
  color: rgba(77, 182, 172, .5);
}

.inputValueType {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 65px;
  top: center;
}

.inputValueTypeThird {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 55px;
  top: center;
}

.removeExtraBtn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.removeExtraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: red;
}

.extraBtn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.extraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: #4db6ac;
}