.form {
  display: grid;
  grid-template-columns: minmax(385px, 20%) minmax(auto, 50%) minmax(100px, 30%);
  margin-top: 0;
  padding: 0;
  height: 90vh !important;
}

.gridItem {
  border: 1px solid #4db6ac;
  max-height: 100%;
  overflow: scroll;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}
@media screen and (max-width: 1500px) {
  .form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; /* set flex direction to row */
  }

  .gridItem:not(:nth-of-type(2)) {
    flex: 1;
    order: 1;
  }

  .gridItem:nth-of-type(2) {
    width: 100%;
  }

  .form > :nth-child(1),
  .form > :nth-child(3) {
    flex-basis: 50%; /* make each item to take up 50% width of the container */
  }
}


@media (max-width: 2100px) {
  .form > :nth-child(3) {
    zoom: 0.65;
  }
}