.form {
  margin: 0 auto;
  padding: 0;
  padding-bottom: 70px;

  width: 940px;

  overflow: auto;
}

.formHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0;
  padding-top: 30px;
  padding-right: 0;
  padding-bottom: 25px;
  padding-left: 0;

  border: none;
}

.fieldName {
  margin: 0;
  margin-right: 25px;
  padding: 0;

  color: #676770;
  font-size: 16px;
}

.departmentField {
  margin: 0;
  padding: 5px 10px;;

  width: 300px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #676770;
  border-left: none;
}

.departmentField::placeholder {
  color: rgba(77, 182, 172, .5);
  font-size: 16px;
}

.inputGroup {
  margin: 0;
  padding: 0;

  border-top: 1px dotted #676770;
  border-right: none;
  border-bottom: none;
  border-left: none;
}

.rateName {
  margin: 0;
  margin-bottom: 25px;
  margin-left: 30px;
  padding: 0 10px;

  color: 676770;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}

.rateSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

  width: 383px;
}

.inputName {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  width: 173px;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.input {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 20px;

  width: 200px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInput {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 30px;

  width: 90px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInputSecond {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 45px;

  width: 120px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.input::placeholder,
.doubleInput::placeholder,
.doubleInputSecond::placeholder {
  margin: 0;
  padding: 0;

  color:rgba(77, 182, 172, .5);
  font-size: 16px;
}

.inputValueType {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 182px;
  top: center;
}

.inputValueTypeSecond {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 265px;
  top: center;
}

.radiobtnContainer {
  margin: 0;
  padding: 0;
}

.shuttleContainer {
  display: flex;
  justify-content: space-between;
}

.shuttleContainer .inputContainer {
  width: fit-content;
}
