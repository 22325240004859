.container {
  margin: 15% 0 0 50%;
  padding: 0;

  width: 30px;
  height: 30px;
  animation: spin 1s infinite linear;
}

.loading {
  width: 30px;
  height: 30px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}