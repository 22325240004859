

.inputBox {
  width: 50px;
  height: 100%;
  text-align: center;
  border: none;
  outline: none;
  box-sizing: border-box;
  border: 1.5px solid #899BA5;
  height: 20px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
  background-color: #899BA5;
  cursor: pointer;
  height: 30px;
  margin-left: 5px;
}

.svgIcon {

  width: 24px;
  height: 24px;
}



