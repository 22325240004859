.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  justify-content: space-between;
  width: 430px; /* 383 500 for sperate component*/
}

.inputName {
  position: relative;
  margin: 0;
  margin-right: 5px;
  padding: 0;
  white-space: nowrap;

/*   width: 500px; */

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.input {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 15px;

  width: 100px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.inputLongPadding {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 65px;

  width: 200px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.input::placeholder,
.inputLongPadding::placeholder {
  margin: 0;
  padding: 0;

  color:rgba(77, 182, 172, .5);
  font-size: 16px;
}

.inputValueType {
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 182 px; 
  top: center;
}
