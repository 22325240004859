.addRateBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  margin-left:450px;
  padding: 5px;

  color: #676770;
  font-size: 18px;
  text-transform: uppercase;

  background-color: inherit;

  border: 1px solid #4db6ac;
  border-radius: 5px;
}

.addRateBtn svg {
  margin-right: 10px;

  width: 25px;
  height: 25px;

  fill: #4db6ac;

}

.removeRateBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 20px auto;
  margin-bottom: 0px;
  margin-left: 450px;
  padding: 5px;

  color: red;
  font-size: 18px;
  text-transform: uppercase;

  background-color: inherit;

  border: 1px solid #4db6ac;
  border-radius: 5px;
}

.removeRateBtn svg {
  margin-right: 10px;

  width: 25px;
  height: 25px;

  fill: red;

}

.titleContainer{
  display: flex;
  border-bottom: 1px solid #000000;
  align-items: center;
  font-size: 18px;
}
.title1
{
  margin: 0;
  margin-right: 10px;
   padding-top: 0;
   padding-right: 0;
   padding-bottom: 5px;
   padding-left: 5px; 
   color: #676770;
   font-size: 24px;
   text-transform: uppercase;
  
}

.vl {
  border-left: 1.5px solid #e1e1e1;
   height: 25px;
   margin-left: -7px;
   margin-right: 5px;
} 

