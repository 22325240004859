.inputContainer {
  position: relative;
  display: flex;
  /* align-items: center; */
  margin: 0;
  /* padding-bottom: 20px; */
  padding: 0;
 /*  justify-content: space-between;
  width: 500px;
  border-bottom: 1px solid rgba(112,112,112, 0.5); */
  
}

.inputName {
  margin: 0;
  margin-right: 6px;
  padding: 0;
  padding-bottom: 15px;


  color: #676770;
  font-size: 16px;
  text-align: right;
}

.radiobtnContainer {
  
  margin: 0;
  padding: 0;
}
.radio{
  margin-left: 15px;
}