.title {
  margin: 0;
/*   margin-bottom: 10px; */
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 5px;
  /* padding-left: 5px; */

  color: #676770;
  font-size: 24px;
  text-transform: uppercase;

/*   border-bottom: 1px solid black; */
  
}