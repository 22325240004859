.container {
  grid-area: oneDayPacking;

  margin: 0;
  padding-top: 10px;
  padding-right: 5px;
  padding-bottom: 0;
  padding-left: 20px;
  border-top: 1px solid #4db6ac;
  /*  border-bottom: 1px solid #4DB6AC; */
}

.unpSepCont {
  border-top: 1px solid #4db6ac;
}

.groupInput {
  display: flex;
  flex-wrap: wrap;

  margin: 0;
  padding: 0;
  width: 100%;

  border: none;
}

.groupInputSU {
  display: flex;
  flex-wrap: wrap;

  margin: 0;
  padding: 0;
  width: 600px;

  border: none;
}

.groupInputSU input {
  margin: 0;
  margin-bottom: 5px;
  margin-top: 2px;
  padding: 0;

  width: 40px;

  text-align: center;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  color: #707070;
  font-weight: 600;
}

.commonLabeling{
}

.decorateContainer {
  margin: 0;
  padding: 0;
  padding-top: 5px;
  width: 100%;
  border-top: 1px solid #4db6ac;
  border-bottom: 1px solid #4db6ac;
  padding-bottom: 10px;
  padding-top: 10px;
}
.decorateContainer1 {
  margin: 0;

}
.divide {
  margin-bottom: 10px;

}
.decorateContainer2 {
  margin: 0;

  padding: 0;
  padding-top: 5px;

  width: 530px;

  padding-bottom: 10px;
/*   border-bottom: 1px solid #4db6ac; */
}


.groupInput input {
  margin: 0;
  margin-bottom: 5px;
  margin-top: 2px;
  padding: 0;

  width: 40px;

  text-align: center;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  color: #707070;
  font-weight: 600;
}
.depcheckbox {
  margin-right: 10px;
}
.inputContainer {
  margin: 0;
  margin-top: 5px;

  padding: 0;
}

.inputContainerSh {
  margin: 0;


  padding: 0;
}

.select {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  padding-left: 10px;

  width: 100px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  color: #707070;
  font-weight: 600;
}

.warning {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;

  color: red;
}
.warning1 {
  margin: 0;
  margin-left: 20px;
  margin-bottom: 5px;
  padding: 0;

  color: red;
}
.commonLabel {
  justify-content: flex-end;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
}

.commonInput {
  margin: 5px;
  padding: 0;
  padding-right: 5px;
  padding-left: 5px;
  text-align-last: center;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  margin-right: 15px;
  color: #707070;
  font-weight: 600;
}
.commonInput1 {
  margin: 5px;
  padding: 0;
  padding-right: 10px;
  padding-left: 5px;
  text-align-last: center;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  margin-right: 15px;
  color: #707070;
  font-weight: 600;
}
.commonInput::placeholder {
  color: rgba(77, 182, 172, 0.5);
}
.mycheckbox {
  padding: 0 !important;
  width: 25px !important;
  height: 18px;
  margin: 0 !important;
  margin-top: 5px !important;
}
.shuttleOnDayOne {
margin-left: 10px;
}
.hLine {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  border-top: 1px solid #4db6ac;
}

.vl {
  border-left: 1.5px solid #e1e1e1;
  height: 25px;
  margin-left: 15px;

}

.dateInput {
  width: 110px !important;
}

.btnSubmit {
  position: relative;
  left: -25%;
  top: -2px;
  float: right;
  z-index: 0;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: #4db6ac;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
}

.up {
  margin-top: 10px;
  border: solid #4db6ac;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  border: solid #4db6ac;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.error {
  color: red; 
  font-size: 14px;
  margin-top: 5px; 
  margin-left: 20px;
}