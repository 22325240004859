.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;

  padding: 0;

  width: 520px;
}

.inputName {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  width: 173px;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.doubleInput {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 30px;

  width: 90px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInputSecond {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 45px;

  width: 120px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInput::placeholder,
.doubleInputSecond::placeholder {
  margin: 0;
  padding: 0;

  color: rgba(77, 182, 172, .5);
  font-size: 16px;
}

.inputValueType {
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;
  left: 145px;

  top: center;
}

.inputValueTypeSecond {
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;
  left: 226px;

  top: center;
}

.eofmiles {
  font-size: 16px;
  color: #676770;
  z-index: 10;
}

.removeExtraBtn {
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.removeExtraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: red;
}