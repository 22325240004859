/* .containerOn {
  position: absolute;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 15px;
  padding-bottom: 5px;
  top: 0;
  left: 0;

  width: 100%;

  background-color: #ffffff;
} */

.container {
  display: block;
}  

.list {
  position: relative;
  right: 10px !important;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  list-style: none;
}

.item {
  margin-right: 20px;
}

.item:last-child {
  margin-right: 0;
}
.user{
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #707070;
  fill: #707070;
  height: 100%;
  border: none;
  background-color: inherit;
}
.link {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #30ad64;
  fill: #30ad64;
  height: 100%;
  border: none;
  background-color: inherit;
}

.link:hover {
  color: #9ec6af;
}

.btnClose {
  position: relative;
  margin: 0;
  padding: 0;

  border: none;
  background-color: inherit;
  cursor: pointer;

  width: 10px;
  height: 10px;
}

.btnClose::before,
.btnClose::after {
  content: "";
  position: absolute;

  height: 2px;
  width: 25px;
  background-color: #30ad64;

  top: 9px;
}

.btnClose::before {
  left: -3px;
  transform: rotate(45deg);
}

.btnClose::after {
  left: -3px;
  transform: rotate(-45deg);
}

.btnClose:hover::before,
.btnClose:hover::after {
  background-color: #9ec6af;
}

@media(min-width: 1155px) {
  .container {
    order: 2;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    margin-left: 260px;

    background-color: #ffffff;
  }

  .item {
    margin-right: 20px;
  }

  .item:last-child {
    margin-right: 0;
  }

  .btnClose {
    display: none;
  }
}
/* @media(min-width: 1900px) {
  .list {
    margin-right: 90px;
  }
} */