.container {
  grid-area: oneDayPacking;
  margin: 0;
  padding-top: 10px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border-top: 1px solid #4db6ac;
  /*  border-bottom: 1px solid #4DB6AC; */
}

.groupInput {

  flex-wrap: wrap;
  margin: 0;
  padding: 0;
 
  border: none;
  /*  border-bottom: 1px solid #4DB6AC; */
}

.hLine {
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 1px solid #4db6ac;
 /*  position: absolute; */
  /*  background-color: #4DB6AC;  */
}



.unpSepCont {
  border-top: 1px solid #4db6ac;
}

.groupInputSecondDay {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0px;
  width: 530px;
  border: none;

}

.decorateContainer {
  margin: 0;
  padding: 0;
  padding-top: 5px;
  width: 100%;
  border-top: 1px solid #4db6ac;
}

.groupInput input {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  width: 40px;
  text-align: center;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  color: #707070;
  font-weight: 600;
}

.inputContainer {
  margin-top: 0;
  margin-right: 15px;
  margin-bottom: 0;

  padding: 0;
}
.inputContainer1 {
  margin-top: 4px;
  margin-right: 15px;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
}
.divide {
  margin-bottom: 10px;
  width: 570px;
}
.select {
  margin: 0;
  margin-bottom: 5px;
  margin-right: 20px;
  padding: 0;
  padding-left: 10px;
  width: 120px;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  color: #707070;
  font-weight: 600;
}

.warning {
  margin: 0;
  margin-bottom: 5px;
  margin-right: 150px;
  padding: 0;
  color: red;
}

.commonLabel {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 0;
}
.depcheckbox {
  width: 30px !important;
}
.commonInput {
  margin: 0;
  padding: 0;
  padding-right: 5px;
  padding-left: 5px;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  margin-right: 10px;
  text-align-last: center;
  color: #707070;
  font-weight: 600;
}
.commonInput1 {
  margin: 0;
  padding: 0;
  padding-right: 5px;
  padding-left: 10px;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  margin-right: 10px;
  text-align-last: center;
  color: #707070;
  font-weight: 600;
}
.commonInput::placeholder {
  color: rgba(77, 182, 172, 0.5);
}

.mycheckbox {
  padding: 0 !important;
  width: 20px !important;
  height: 16px;
  margin: 0 !important;
  margin-top: 5px !important;
}
.shuttleOnDayOne {
  margin: 0;
  margin-left: 20px;
}
.procedure {
  margin-left: 0px;
}

.hLine {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #4db6ac;
  padding: 0;
  width: 100%;
}


.vl {
  border-left: 1.5px solid #e1e1e1;
  height: 25px;
  margin-left: 15px;
  margin-right: -30px;
}

.dateInput {
  width: 110px !important;
}

.btnSubmit {
  position: relative;
  left: -25%;
  top:-22px;
  float: right;
  z-index: 0;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: #4db6ac;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
}

.up {
  margin-top: 5px;
  border: solid #4db6ac;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  border: solid #4db6ac;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.error {
  color: red; 
  font-size: 14px;
  margin-top: 5px; 
  margin-left: 20px;
}