.rateGroup {
  margin: 0;
  padding: 0;

  border-top: 1px dotted #676770;
  border-right: none;
  border-bottom: none;
  border-left: none;
  margin-bottom: 20px;
}

.rateName {
  margin: 0;
  margin-bottom: 10px;
  margin-left: 30px;
  padding: 0 10px;

  color: 676770;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}

.rateSection {
  display: flex;
 /*  justify-content: space-between; */
  flex-wrap: wrap;
  align-items: center;
  border-bottom:1px solid rgba(112,112,112, 0.5);
  margin-left: 5px;
  padding: 0;
  align-items: center;
  margin-top: 10px;
}

.decorateLine {
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0;

  width: 720px;
  height: 1px;

  background-color: #4db6ac;
}


.optionsContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  width: 300px;
}

.removeRateBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  padding: 5px;

  color: red;
  font-size: 18px;
  text-transform: uppercase;

  background-color: inherit;

  border: 1px solid #30AD64;
  border-radius: 5px;
}

.removeRateBtn svg {
  margin-right: 10px;

  width: 25px;
  height: 25px;

  fill: red;

}

.input {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 25px;

  width: 200px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
  margin-left: 10px;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

  width: 383px;
}

.flexDiv {
  display: flex;
  margin-left: 5px;
  border-bottom:1px solid rgba(112,112,112, 0.5);
  
}

.andincButton{
  color: white;
  border: none;
  background-color: #4db6ac;
  border-radius: 5px;
}