.btnSubmit {
  display: block;
  margin-top:10px;
  margin-right: 30px;
  margin-bottom: auto;
  margin-left: auto;

  padding-top: 10px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-left: 30px;

  cursor: pointer;

  background-color: #4DB6AC;
  border: none;
  border-radius: 4px;

  color: #ffffff;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
}
/*
.btnSubmit:hover {
  box-shadow: 2px 2px 2px 0 #000000;
}
 */
.btnSubmit:disabled {
  background-color: rgba(128, 128, 128, 0.5);
}

.btnSubmit:disabled:hover {
  cursor: not-allowed;
  box-shadow: none;
}