.paramsSection {
  margin: 0;
  padding: 0;
  width: 450px;
  list-style: none;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
}

.paramsItem {
  display: grid;
  grid-template-columns: auto 1fr 200px;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  width: 100%;
}

.paramsName {
  margin: 0;
  padding: 0;
}

.paramsDecor {
  display: block;
  width: 100%;
  height: 100%;
  border-bottom: 3px dotted #707070;
}

.paramsValue {
  margin: 0;
  padding: 0;
  width: 270px;
}

.title {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: red;
}

.btnSubmit {
  position: relative;
  top: -35px;
  float: right;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;

  cursor: pointer;

  background-color: #30ad64;
  border: none;
  border-radius: 4px;

  color: #ffffff;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
}

.up {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.selectedInfo {
  display: flex;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  /* margin-left: 135px; */
  /*  position:relative; 
  right:-35%;  */
  padding: 0;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
}

.departmentName {
  margin: 0 20px 0 0;

  color: #4db6ac;
  font-size: 16px;
  text-transform: capitalize;
  /* width:400px; */
  text-align: center;
}

.rateName {
  margin: 0;
  padding: 0;
  color: red;
  font-size: 16px;
  text-transform: capitalize;
}

.highLighted {
  background: yellow;
  border: 1px solid black;
  border-radius: 10%;
  padding: 3px;
}
