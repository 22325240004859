.header {
  min-width: 280px;
  max-width: 1080px;
  width: 100%;
}

.titles {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding-top: 0;
  padding-right: 40px;/* 20px */
  padding-bottom: 10px;
  padding-left: 0px;/* 20px */

  list-style: none;

  border-bottom: 2px solid #30ad64;
}

.titleItem {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  font-size: 14px;
}

.titleItem:nth-child(2) {
  display: none;
}

.sortBtn {
  margin: 0;
  padding: 0;
  padding-left: 5px;

  cursor: pointer;

  background-color: inherit;
  border: none;
  display: none;
}

.sortBtnAsc {
  margin: 0;
  padding: 0;
  padding-left: 5px;
  transform: rotate(180deg);
  cursor: pointer;

  background-color: inherit;
  border: none;
  display: none;
}

@media(min-width: 500px) {
  .titleItem:nth-child(2) {
    display: block;
  }
}

@media(min-width: 768px) {
  .titleItem {
    font-size: 16px;
  }
}
