.commonLabel {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 0;

  
}
.commonLabelB {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 0;

  
  border: none;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  margin-bottom: 20px;
  padding-bottom: 20px;
}
/* .commonLabel:nth-child(3) {
  border: none;

  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  margin-bottom: 20px;
  padding-bottom: 20px;
} */
.commonInput {
  margin: 0;
  margin-left: 5px;
  padding: 0;
  padding-right: 20px;
  padding-left: 5px;
  text-align-last: center;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;

  width: 95px;
  color: #707070;
  font-weight: 600;
}

.commonInput::placeholder {
  color: rgba(77, 182, 172, 0.5);
}

/*.commonInput::-webkit-inner-spin-button {
  -webkit-appearance: none; 
}*/
