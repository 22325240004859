@font-face {
    font-family: "Arial";
    src: local("Arial"), url("./font/ArialMT.woff"), url("./font/ArialMT.woff2");
    font-weight: 400;
    font-style: bold;
    font-display: swap;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: Arial;
    font-size: 15px;
     font-weight: 600;
    color: #707070;
    line-height: 1.3;
    min-width: 320px;
    width: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #70757a;
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
    position: absolute;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    border: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.container {
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

.container-auth {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    margin-top: 140px;
}

.main {
    min-height: calc(100vh - 249px);
}

.authMain {
    min-height: calc(100vh - 190px);
}

.auth {
    padding-top: 50px;
    padding-bottom: 40px;
}

.uppercase {
    text-transform: uppercase;
}

@media(min-width: 768px) {
    .main {
        min-height: calc(100vh - 242px);
    }
}


/* Remove Arrows/Spinners */


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}