.btnSubmit {
  display: block;


  width: 200px;

  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;

  cursor: pointer;

  background-color: #0000ff;
  border: none;
  border-radius: 4px;

  color: #ffffff;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
}

.btnSubmit:disabled {
  background-color: rgba(128, 128, 128, 0.5);
}

.btnSubmit:disabled:hover {
  cursor: not-allowed;
  box-shadow: none;
}

