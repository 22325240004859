.feeTypeSelect {
  margin: 0;
  margin-bottom: 10px;
  margin-left: 120px;
  padding: 5px 10px;
  ;

  width: 300px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #676770;
  border-left: none;
}

.fieldName {
  margin: 0;
  margin-right: 25px;
  padding: 0;

  color: #676770;
  font-size: 16px;
}

.container {
/*   display: flex;
 */  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
  border-bottom:1px solid rgba(112,112,112, 0.5);
}



.extraBtn {
  align-items: center;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: 155px;
  white-space: nowrap;
  padding: 0;
  display: flex;
  border: none;
  background-color: inherit;
}

.extraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: #4db6ac;
}

.removeExtraBtn {
  align-items: center;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;
  display: flex;
  border: none;
  background-color: inherit;
}

.removeExtraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: red;
}

.addParent {
  width: 131px;
}
.inputContainer {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 15px;
    padding: 0;
    border-bottom:1px solid rgba(112,112,112, 0.5);
}
.contan
{
  border-bottom:1px solid rgba(112,112,112, 0.5);
  margin-bottom: 25px;
  
}