/* SocketOverlay.module.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.message {
  color: white;
  text-align: center;
  font-size: 3rem;
}

.subMessage {
  font-size: medium;
}