.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  width: 400px; /* 383 500 for sperate component*/
  /* border-bottom: 1px solid rgba(112,112,112, 0.5); */
}


.inputName {
  margin: 0;
  margin-right: 55px;
  padding: 0;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.input {
  position: relative;
  margin: 0;
  
  padding: 0;
  padding-left: 25px;

  width: 100px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.inputLongPadding {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 25px;

  width: 100px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.input::placeholder,
.inputLongPadding::placeholder {
  margin: 0;
  padding: 0;

  color:rgba(77, 182, 172, .5);
  font-size: 16px;
}

.inputValueType {
  
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 182px;
  top: center;
}
