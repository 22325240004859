.inventoryContainer {
    display: flex;
}

.listColumn {
    display: flex;
    flex-direction: column;
    width: 15%;
    padding: 5px;
    align-items: flex-start;


}

.listColumnWORN {
    display: flex;
    flex-direction: column;
    min-height: 250px;
    align-items: flex-start;
}

.detailsColumn {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    /* Disable horizontal scroll */
    overflow-y: auto;
    /* Enable vertical scroll */
    width: 85%;
    padding: 5px;
    height: 100vh;
}

.buttonContainer {
    display: inline-block;
    margin-right: 15px;
}

.extraBtn {
    font-size: 17px;
    display: flex;
    align-items: center;
    color: #707070;
    margin: 10px auto;
    padding: 0;
    text-transform: uppercase;
    border: none;
    background-color: inherit;
    cursor: pointer;
}

.extraBtn svg {
    margin-right: 5px;
    width: 30px;
    height: 30px;
    fill: #4db6ac;
}



.extraStopBtn {
    font-size: 15px;
    display: flex;
    align-items: center;
    color: #707070;
    padding: 0;
    border: none;
    background-color: inherit;
}

.extraStopBtn svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    fill: #4db6ac;
}

.roomList {
    width: 100%;
    /* Adjust width as needed */
}

.roomStyle {
    background-color: #aecacb;
    /* Light teal color */
    color: white;

    margin-bottom: 5px;
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    padding-left: 5px;
}

.highlightedRoomStyle {
    /* Light teal color */

    margin-bottom: 5px;
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    background-color: white;
    /* White background for highlighted room */
    color: #555555;
    text-align: left;
    padding-left: 5px;
    /* Darker text color */
}

.styledInput {
    display: flex;
    align-items: center;
    background-color: #d0ebf4;
    border: 2px solid #7b7d3b;
    border-radius: 25px;
    padding: 2px 5px;
    width: 120px;
    height: 30px;
    font-family: Arial, sans-serif;
}

.styledInput .number {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d0d8f0;
    /*  border: 2px solid #7b7d3b; */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    color: #ffffff;

}

.styledInput input {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-bottom: 1px solid black;
    flex: 1;
    margin-right: 10px;
}

.roomNameInput {
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid black;
}

.removeExtraBtnList {
    display: flex;
    align-items: center;
    margin-right: 0;
    padding: 0;
    border: none;
    background-color: inherit;
}

.removeExtraBtnList svg {
    margin-left: 5px;
    width: 15px;
    height: 15px;
    fill: red;
}

.boxCont {

    border-radius: 10px;
    width: 100%;
    margin-top: 5px;
    margin-left: 10px;
    background: 'white';
    min-height: '100px';
    max-width: 200px;
    min-width: 200px;

}

.boxesCont {
    display: flex;
    flex-wrap: wrap;
}


.cuftDetailsCont {
    flex: 1 1 50%;
    border-bottom: 1px solid #707070;
    padding-top: 5px;
    padding-bottom: 2px;
}



.resultsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    /* overflow: auto;
    padding: 5px; */
    min-height: 120px;
    max-height: 120px;
}

.resultsColumn {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    padding: 5px 0;
    position: relative;
}

.furnitureTitle {
    flex: 1;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 240px;
}


.resultsColumn:not(:last-of-type):before {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    right: -15px;
    width: 1px;
    background: #000;
}

.resultsRow {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.resultsHighlight {
    color: red;

}


.commonLabel {
    display: flex;
    justify-content: flex-end;
    /*  font-size: 1vmax; */
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-right: 20px;
    padding-left: 0px;
}

.commonInput {
    margin: 0;
    margin-left: 5px;
    padding: 0;

    text-align-last: center;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid #707070;
    border-left: none;
    color: #707070;
    font-weight: 600;

    width: 110px;
}

.disabledButton {
    background-color: #ccc;
    /* Gray background */
    color: #666;
    /* Darker text */
    cursor: not-allowed;
    /* Show a 'not-allowed' cursor */
}

.goesTitle {
    font-size: 16px;
    color: #4db6ac;
}

.staysTitle {
    font-size: 16px;
    color: red;
}

.resultsContainer::-webkit-scrollbar {
    width: 6px;
}

.resultsContainer::-webkit-scrollbar-thumb {
    background: #888;
    /* Scrollbar handle color */
    border-radius: 10px;
    /* Rounds the edges of the scrollbar thumb */
}

@media (max-width: 1200px) {
    .cuftDetailsCont {
        flex: 1 1 100%;
    }

    .furnitureTitle {
        min-width: 100px;
    }

    .listColumn {

        min-width: 180px;
    }
}

.hLine {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    border-top: 1px solid #4db6ac;
}