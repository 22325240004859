.custom_dialog {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  /* Styling for the close button */
  .custom_dialog_close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }