.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  justify-content: space-between;
     width: 700px; 
}

.inputName {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  /*  width: 270px; */

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.minusStyle {
  padding-left: 5px;
  padding-right: 5px;
}

.inputNameFinish {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  width: 173px;

  color: #676770;
  font-size: 16px;
}

.doubleInput {
  position: relative;
  margin: 0;
  padding: 0;

  width: 60px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInputSecond {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 15px;

  width: 100px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInput::placeholder,
::placeholder {
  margin: 0;
  padding: 0;

  color: rgba(77, 182, 172, 0.5);
  font-size: 16px;
}

.inputValueType {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 600px;
  top: center;
}

.inputValueTypeSecond {
  position: relative;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  width: 60px;
  top: center;
}

.eofmiles {
  font-size: 16px;
  color: #676770;
  z-index: 10;
}

.distanceContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;

  /*   width: 776px; */
}
