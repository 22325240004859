.container {
  margin: 0;
  margin-right: 20px;
/*   margin-bottom: 30px; */
  margin-left: 20px;
  padding: 0;

  min-width: 280px;
  max-width: 940px;
}

@media(min-width: 768px) {
  .container {
/*     margin-bottom: 40px; */
  }
}

@media(min-width: 1440px) {
  .container {
/*     margin-bottom: 60px; */
  }
}
