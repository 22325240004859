.tagsContainer {
  position: relative;
  padding: 5px;
  display: flex;

}

.tagNameInp {

}
