.btnSubmit {
  display: block;


  width: 200px;

  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;

  cursor: pointer;

  background-color: red;
  border: none;
  border-radius: 4px;

  color: #ffffff;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
}
/* 
.btnSubmit:hover {
  box-shadow: 2px 2px 2px 0 #000000;
}
 */
.btnSubmit:disabled {
  background-color: rgba(128, 128, 128, 0.5);
}

.btnSubmit:disabled:hover {
  cursor: not-allowed;
  box-shadow: none;
}
/* @media (max-width: 1370px) {
  .btnSubmit {
    margin-right: 150px;
  }
}
@media (max-width: 1155px) {
  .btnSubmit {
    margin-right: 150px;
  }
} */

/* @media(max-width: 768px) {
  .btnSubmit {
    margin-left: 850px;
    margin-right: auto;
  }
}   */
