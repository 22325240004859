.packingContainer {
  flex-wrap: wrap;
  /*  justify-content: space-between; */
  /* margin: 0 auto; */
  padding: 0;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);

}

.packingContainersecond {
  display: flex;
  flex-wrap: wrap;
  /*  justify-content: space-between; */
  /* margin: 0 auto; */
  padding: 0;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);

}

.inputNameSecond {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  width: 55px;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.inputValueType {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 65px;
  top: center;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

}

.inputContainerSecond {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

  width: 162px;
}

.packingWrapper {
  margin-bottom: 25px;
  padding: 0;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);

  /* width: 800px; */
}

/*  */
.fixKitchen {
  width: 400px;
}

.packingContainersec {
  display: flex;
  flex-wrap: wrap;
  /* margin: 0 auto; */
  padding: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);


}

.buttonContainer {
  display: inline-block;
}

.removeExtraBtn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.removeExtraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: red;
}

.extraBtn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.extraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: #4db6ac;
}

.removeExtraBtnList {
  display: flex;
  align-items: center;

  margin-right: 0;

  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.removeExtraBtnList svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: red;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

  width: 400px;
}

.inputName {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  width: 173px;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.inputNameFinish {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  width: 173px;

  color: #676770;
  font-size: 16px;
}

.doubleInput {
  position: relative;
  margin: 0;
  padding: 0;


  width: 30px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInputSecond {
  position: relative;
  margin: 0;
  padding: 0;


  width: 100px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.input::placeholder,
.inputSecond::placeholder {
  color: rgba(77, 182, 172, .5);
}

.doubleInput::placeholder,
.doubleInputSecond::placeholder {
  margin: 0;
  padding: 0;

  color: rgba(77, 182, 172, .5);
  font-size: 16px;
}

.inputValueType {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 145px;
  top: center;
}

.feeTypeSelect {
  margin: 0;
margin-left: 10px;
  margin-right: 10px;

  width: 120px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #676770;
  border-left: none;
}

.inputValueTypeSecond {
  position: relative;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  width: 60px;
  top: center;
}

.eofmiles {
  font-size: 16px;
  color: #676770;
  z-index: 10;
}

.distanceContainer {
  display: flex;
  flex-wrap: wrap;
  /*   justify-content: space-between; */
  /* margin: 0 auto; */
  padding: 0;

  /*  width: 776px; */
}