.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  max-width: 800px;
  overflow-x: auto;
  margin-top: 60px;
}

.pages {
  display: flex;
  flex-wrap: wrap;
}

.page {
  margin: 0 10px 10px 0;
  /* add margin-bottom to add a gap between rows */
  padding: 5px 10px;
  border: 1px solid #30ad64;
  border-radius: 3px;
  font-size: 16px;
  text-decoration: none;
  color: #30ad64;
  transition: all 0.2s ease-in-out;
}

.page:hover {
  background-color: #30ad64;
  color: #fff;
}

.active {
  background-color: #30ad64;
  color: #fff;
}

.prev,
.next {
  margin: 0 10px 10px 0;
  /* add margin-bottom to add a gap between rows */
  padding: 5px 10px;
  border: 1px solid #30ad64;
  border-radius: 3px;
  font-size: 16px;
  text-decoration: none;
  color: #30ad64;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
}

.prev:hover,
.next:hover {
  background-color: #30ad64;
  color: #fff;
}

.prev:hover:before,
.next:hover:before {
  border-color: #fff;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.prev:before,
.next:before {
  content: "";
  display: inline-block;
  border: solid #30ad64;
  border-width: 0 2px 2px 0;
  padding: 3px;
  margin-right: 5px;
  margin-left: 5px;
}

.prev:before {
  transform: rotate(135deg);
}

.next:before {
  transform: rotate(-45deg);
}

.per-page {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 10px;
  /* add margin-bottom to create space between Per Page and pagination items */
}

.per-page span {
  margin-right: 5px;
}

.per-page select {
  margin-left: 5px;
  padding: 5px 10px;
  border: 1px solid #30ad64;
  border-radius: 3px;
  font-size: 16px;
  color: #30ad64;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
}

.per-page select:hover {
  background-color: #f2f2f2;
}

.per-page select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #30ad64;
}

.dots {
  display: inline-block;
  margin-right: 10px;
  color: #999999;
}