.custom_select {
  /* Remove default select arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 24'%3E%3Cpath fill='hsl(0, 0%, 80%)' d='M 29.338 7.804 C 29.774 7.358 30.381 7.323 30.914 7.804 L 34.822 11.551 L 38.73 7.804 C 39.263 7.323 39.871 7.358 40.304 7.804 C 40.74 8.249 40.712 9.001 40.304 9.419 C 39.898 9.837 35.609 13.921 35.609 13.921 C 35.392 14.144 35.107 14.256 34.822 14.256 C 34.537 14.256 34.252 14.144 34.033 13.921 C 34.033 13.921 29.746 9.837 29.338 9.419 C 28.93 9.001 28.902 8.249 29.338 7.804 Z'%3E%3C/path%3E%3Crect x='14.003'  width='1' height='20.605' style='stroke: rgb(0, 0, 0); fill-rule: nonzero; paint-order: stroke markers; fill: rgb(204, 204, 204); stroke-width: 0px;'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 3em 1.5em;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 3rem 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}


.tagsContainer {
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.tagNameB {
  font-weight: bold;
  font-size: 16px;
}

.tagNameReg {}


.itemCont {
  margin-bottom: 10px;
  /*   padding-bottom: 10px; */
  font-weight: normal;
}

.btnSubmit {
  position: relative;

  float: right;
  padding: 5px 25px 5px 10px;
  cursor: pointer;

  background-color: #30ad64;
  border: none;
  border-radius: 4px;

  color: #ffffff;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
  z-index: 3;
}

i {
  position: absolute;
  top: 10px;
  right: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotateZ(45deg);
  transform-origin: center;
  transition: 0.3s;
}

i.up {
  transform: rotateZ(225deg) translateY(-25%);
}

.btnSubmitSame {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 5px 10px;
  height: 25px;

  border: 1px solid #30ad64;
  border-radius: 5px;

  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #676770;
  background-color: inherit;
  cursor: pointer;
}



.btnSubmitSame:hover {
  color: #ffffff;
  background-color: #4db6ac;
}

.btnSubmit {
  margin-left: auto;
  width: 10px;
}

.btnSubmit+div {
  position: relative;
}

.btnSubmit+div:after {
  content: "";
  position: absolute;
  right: 2px;
  bottom: 0;
  height: calc(100% + 35px);
  border-left: 2px dashed #4db6ac;
}

.chbox {
  padding: 0 !important;
  width: 25px !important;
  height: 18px;
  margin: 0 !important;
  margin-top: 5px !important;
}

.btnSubmit+div:before {
  content: "";
  position: absolute;
  left: 10px;
  right: 2px;
  bottom: 0;
  border-bottom: 2px dashed #4db6ac;
}

.buttonContainer {
  position: relative;
  margin-right: 40px;
  margin-bottom: 10px;
  padding-bottom: 15px;
}

.buttonContainer:after {
  content: "";
  position: absolute;
  left: 10px;
  bottom: 0;
  height: 15px;
  border-left: 2px dashed #4db6ac;
}

.blockHead {
  display: flex;
  align-items: center;
}

.ifBlock {
  position: relative;
  margin-right: 40px;
}

.blockHead input {
  max-width: 200px;
  width: 100%;
  height: 30px;
  margin-right: 10px;
  padding: 0 5px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.blockHead input:disabled {
  background-color: #fafafa;
}

.blockHead img {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  object-fit: contain;
}

.topBlock,
.middleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.topBlock>span {
  position: relative;
  font-size: 30px;
  color: #4db6ac;
}

.topBlock>span:before {
  content: "";
  position: absolute;
  left: 10px;
  bottom: 100%;
  height: 20px;
  border-left: 2px dashed #4db6ac;
}

.topBlock>div {
  flex: 1;
  margin: 0 0 0 15px;
}

.topBlock button {
  position: absolute;
  left: calc(100% + 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  color: #fff;
  background-color: red;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.middleBlock .firstSelect {
  width: 25%;
}

.middleBlock .secondSelect {
  width: 70%;
}

.middleBlock label {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.middleBlock label:first-of-type {
  flex: 1;
}

.middleBlock label input[type="text"] {
  width: 100%;
  height: 38px;
  padding: 0 5px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.middleBlock label input[type="text"]:disabled {
  background-color: #fafafa;
}

.bottomBlock>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.bottomBlock>div span {
  position: relative;
  font-size: 30px;
  color: #4db6ac;
}

.buttonContainer .bottomBlock>div>span:before {
  content: "";
  position: absolute;
  left: 10px;
  bottom: 100%;
  height: 20px;
  border-left: 2px dashed #4db6ac;
}

.bottomBlock textarea {
  width: 100%;
  height: 80px;
  border-color: #ccc;
  border-radius: 4px;
}

.bottomBlock .select>div {
  width: 100%;
}

.removeExtraBtn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.removeExtraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: red;
}

.extraBtn {
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 0;

  border: none;
  background-color: inherit;
}

.extraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: #4db6ac;
}

@media screen and (max-width: 1500px) {
  form {
    display: flex;
    flex-wrap: wrap;
  }
}

.hLine {
  margin: 0;
  margin-left: -20px;
  padding: 0;
  margin-top: 10px;

  width: 600px;
  border-top: 1px solid #ccc;
  ;

  /*  background-color: #4DB6AC;  */
}