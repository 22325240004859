.container {
  margin: 0 auto;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 50px;

  min-width: 320px;
  max-width: 940px;
}

.title {
  margin: 0;
  margin-bottom: 23px;
  padding: 0;

  color: #676770;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  text-transform:uppercase;
}

.list {
  margin: 0 auto;
  padding: 0;
  list-style: none;

  width: 280px;
}

.item {
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0;
}

.item:last-child {
  margin-bottom: 0px;
}

@media(min-width: 768px) {
  .container {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .title {
    margin-bottom: 80px;
    font-size: 30px;
  }

  .list {
    width: 460px;
  }

  .item {
    margin-bottom: 50px;
  }
}

@media(min-width: 1440px) {
  .container {
    padding-top: 90px;
    padding-bottom: 80px;
  }

  .title {
    margin-bottom: 96px;
  }

  .list {
    display: flex;
    justify-content: space-between;
    width: 940px;
  }

  .item {
    margin: 0;
    width: 460px;
  }
}
