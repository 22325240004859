.form {
  display: grid;
  margin-top: 0;
  margin-right: 20px;
  margin-bottom: 100px;
  margin-left: 20px;
  padding: 0;
  width: 1000px;
}

/* .fieldset {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  padding: 0px;
  padding-top: 25px;
  padding-left: 100px;
  padding-right: auto;
  font-size: 16px;
  border: none;
} */

.fieldset {
  align-items: center;
  border: none;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  margin: 0;
  padding: 25px auto 0 100px;
}

.notInvBtn img {
  display: none;
  height: 15px;
}

.notInvBtn img.default {
  display: inline;
}

.btn img {
  display: none;
  height: 15px;
}

.btn img.default {
  display: inline;
}

.btn:hover img.default {
  display: none;
}

.btn:hover img.hover {
  display: inline;
}

@media (max-width: 1380px) {
  .fieldset {
    display: inline;
    padding-left: 50px;
    width: 900px;
  }
}

.label {
  margin: 0;
  padding: 0;
  padding-right: 0px;
  padding-left: 30px;
  white-space: nowrap;
}

.input {
  margin: 0;
  margin-left: 5px;
  padding-top: 6px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #5ebdb4;
  border-left: none;
  width: 200px;

  font-family: Arial;
  color: #707070;
  font-weight: 600;
}

.input::placeholder {
  margin: 0;
  padding: 0;
  color: rgba(77, 182, 172, 0.5);
  text-align: center;
  text-align-last: center;
}

.select {
  margin: 0;
  margin-left: 5px;
  padding-right: 15px;
  padding: 6px 10px;
  text-align-last: center;
  width: 250px;
  border: 2px solid #707070;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #5ebdb4;
  border-left: none;
  color: #707070;
  font-weight: 600;
}

.greedContainer {
  display: grid;
  grid-template-columns: 600px auto;
  grid-template-rows: 25px 105px auto 1fr;
  grid-template-areas:
    "empty tabMenu"
    "commonValues  oneDayPacking"
    "commonValues  oneDayPacking"
    "commonValues  resultBox"
    "commonValues  footer";
}

/* @media(min-width: 2000px) {
  .form {
    margin-right:auto;
    margin-left: auto;
  }
} */
@media (max-width: 1220px) {
  .greedContainer {
    grid-template-columns: 300px auto;
  }
}

.sLabel {
  margin-left: 10px;
  display: inline-block;
  width: 100%;
  margin-right: 10px;
}

.sidebarContainer {
  margin-top: 10vh;
}



.tarea {
  display: block;
  margin-left: 10px;
  width: 90%;
  margin-right: 10px;
}

.showSideBar {
  background: rgb(237, 244, 247);
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100%;
  padding: 20px 0;
  transition: all 0.5s ease;
}

.hideSideBar {
  background: rgb(237, 244, 247);
  position: fixed;
  top: 0;
  right: -350px;
  width: 350px;
  height: 100%;
  padding: 20px 0;
  transition: all 0.5s ease;
}

.showCustomTags {
  background: rgb(237, 244, 247);
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100%;
  padding: 20px 0;
  transition: all 0.5s ease;
  z-index: 3;
}

.hideCustomTags {
  background: rgb(237, 244, 247);
  position: fixed;
  top: 0;
  right: -350px;
  width: 350px;
  height: 100%;
  padding: 20px 0;
  transition: all 0.5s ease;

}

.showInventory {
  background: rgb(237, 244, 247);
  position: fixed;
  top: 0;
  left: 0;
  /* Changed from right to left */
  width: 1170px;
  height: 100%;

  transition: all 0.5s ease;
}

.hideInventory {
  background: rgb(237, 244, 247);
  position: fixed;
  top: 0;
  left: -1170px;
  /* Changed from right to left */
  width: 1170px;
  height: 100%;
  transition: all 0.5s ease;
}

.inventoryButton {
  position: absolute;
  top: 41px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
  border: none;
  top: 35px;
  height: 37px;
  width: 37px;
  background-color: #4DB6AC;
  border: none;
}

/* .inventoryButton:hover {
  background-color: rgba(255, 255, 255, 0.2);
} */

@media (max-width: 1200px) {
  .hideInventory {}

  .showInventory {
    width: 1050px;
  }
}


.sideBarButton {
  position: fixed;
  right: 5px;
  top: 85px;
  border: none;
  color: white;
  background-color: white;

  background: transparent;
}

.customTagsButton {
  position: fixed;
  right: 5px;
  top: 125px;
  border: none;
  color: white;
  background-color: white;

  background: transparent;
}


.buttonWithCircle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
}



.notInvBtn{
  color: #676770;
  background-color: inherit;
  cursor: pointer;

  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 5px 10px;
  margin-right: 50px;

  border: 1px solid #30ad64;
  border-radius: 5px;

  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}


.btn {
  color: #676770;
  background-color: inherit;
  cursor: pointer;

  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 5px 10px;
  margin-right: 50px;

  border: 1px solid #30ad64;
  border-radius: 5px;

  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.btn:hover {
  color: #ffffff;
  background-color: #4db6ac;
}

.groupCalcsCont {

  margin-top: 20px;

  width: 100%;
  min-width: 320px;
  max-width: 1080px;
  min-height: 150px;



}

@media(min-width: 768px) {
  .groupCalcsCont {
    margin-right: auto;
    margin-left: 80px;

    width: 100%;
  }
}