.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
/*   border-bottom:1px solid rgba(112,112,112, 0.5);
 */}

.containerSecond {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.buttonContainer {
  display: inline-block;
  margin-left: 30px;
  
}

.shuttleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shuttleContainer .optionsContainer {
  width: fit-content;
}
.contaner{
  margin-left: 5px;
  margin-bottom: 20px;
  border-bottom:1px solid rgba(112,112,112, 0.5);
}
.optionsContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 15px;
  padding: 0;


}

.radiobtnContainer {
  display: flex;
  margin: 0;
  margin-top: 10px;
  margin-left: 10px;
  padding: 0;

}


.inputName {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.inputNameSecond {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  width: 55px;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

}

.inputContainerSecond {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

  width: 162px;
}

.input {
  position: relative;
  margin: 0;
  margin-right: 10px;
  padding: 0;
  padding-left: 5px;

  width: 130px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}



.inputSecond {
  position: relative;
  margin: 0;
  margin-right: 5px;
  padding: 0;
  padding-left: 25px;

  width: 100px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.input::placeholder, .inputSecond::placeholder {
  color: rgba(77, 182, 172, .5);
}

.inputValueType {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 65px;
  top: center;
}

.removeExtraBtn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.removeExtraBtnList {
  display: flex;
  align-items: center;

  margin-right: 0;

  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.removeExtraBtnList svg{
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: red;
}

.removeExtraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: red;
}

.extraBtn {
  display: flex;
  align-items: center;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.extraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: #4db6ac;
}
.hLine {
  margin: 0;
  padding: 0;

  width: 1000px;
  /* border-top:1px solid #4DB6AC; */
} 
.shutLine {
  margin: 0;
  padding: 0;

  width: 830px;
   border-top:0.5px solid rgba(112,112,112, 0.5); 
} 