.sectionTitle {
  margin-top: 30px;
  margin-right: 0;
  margin-bottom: 10px;
  padding: 0;

  color: #676770;
  font-size: 24px; 
  text-transform: uppercase;
   border-bottom: 1px solid black;
}
