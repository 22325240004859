.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 5;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 60%;
  height: 50%;
  max-width: 1500px;
}

.popupMain {
  position: fixed;
  padding: 0;
  margin: 0;
  z-index: 40;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.close {
  position: absolute;
  top: 3px;
  right: 13px;
  border: none;
  background-color: transparent;
  filter: alpha(opacity=20);
  opacity: 1 !important;
}

.close span {
  font-size: 20px;
  color: #000 !important;
  cursor: pointer;
}

.editorStyle {
  color: black;
  font-weight: normal;
}

.content {
  background: white;
  font-weight: normal;
  color: black;
  overflow: auto;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.title {
  padding: 5px 10px;
  background-color: #bad5ce;
}

.inputWrapper {
  position: relative;
  margin: 5px 10px;
  border-bottom: 1px solid #f1f1f1;
}

.secondInputWrapper {
  display: flex;
  position: relative;
  margin: 5px 10px;
  border-bottom: 1px solid #f1f1f1;
}

.inputWrapper input {
  width: 100%;
  padding: 5px 0;
  box-shadow: none;
  outline: none;
  border: none;
}

.secondInputWrapper input {
  width: 100%;
  padding: 5px 0;
  box-shadow: none;
  outline: none;
  border: none;
}

.inputWrapper input::placeholder {
  color: #f1f1f1;
}

.secondInputWrapper input::placeholder {
  color: #f1f1f1;
}

.to {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.to span {
  color: #f1f1f1;
  margin-left: 10px;
  transition: 0.3s;
  cursor: pointer;
}

.to span.active {
  color: #000;
}

.to span:hover {
  color: #000;
}

.message {
  padding: 0 10px;
}

.messageHidden {
  position: absolute;
  left: -9999px;
}


.btn,
.activeBtn {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 5px 10px;
  height: 35px;

  border: 1px solid #30ad64;
  border-radius: 5px;

  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.btn {
  color: #676770;
  background-color: inherit;
  cursor: pointer;
  min-width: 200px;
}

.btn:hover {
  color: #ffffff;
  background-color: #4db6ac;
}

.icon {
  height: 20px;
}