/* QuantityInput.module.css */
.buttonContainer {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: #899BA5;
  cursor: pointer;
}

.inputBox {
  width: 40px;
  height: 20px;
  text-align: center;
  border: 1.5px solid #899BA5;
  box-sizing: border-box;
  margin: 0 1px;
}

.svgIcon {
  width: 25px;
  height: 25px;
}

.buttonDisabled {
  background-color: #ccc; /* Example disabled background color */
  cursor: not-allowed;
}