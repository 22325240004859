.title {
  margin: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;

  font-size: 18px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}

@media(min-width: 768px) {
  .title {
    font-size: 36px;
  }
}