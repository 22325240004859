.container {
  /*  min-width: 320px; */
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  /* margin-left: 300px; */
  /* border-bottom: 1px solid #30ad64;*/
}


.list {
  display: flex;
  align-items: center;
  /*  justify-content: center; */
  position: relative;
  right: 20px !important;
  margin: 0;

  /*padding: 15px 20px; 15px 20px */
  padding-top: 5px;
  padding-left: 1px;
  /*  width: 100%;
  max-width: 0px;
+ 940 */
  list-style: none;

}

@media (max-width:760px) {
  .list {
    padding: 1px !important;
  }

}

.item {
  margin-bottom: 5px;
}

/* .item:last-child {
  margin-bottom: 0;
} */
/* @media (max-width:756px) {
  .container {
    margin-left: 320px;
  }

} */

.btn,
.activeBtn {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 2.5px 5px;

  border: 1px solid #30AD64;
  border-radius: 3px;

  font-size: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.btn {
  color: #ffffff;
  border: 1px solid #ffffff;
  background-color: inherit;
  cursor: pointer;
}

.btn:hover {
  color: #4db6ac;
  background-color: #ffffff;
  border: 1px solid #30AD64;
}

.activeBtn {
  color: #4db6ac;
  background-color: #ffffff;
}



@media(min-width: 500px) {

  .item {
    margin-right: 20px;
  }

  .item:last-child {
    margin-right: 0;
  }
}



.iconContainer {
  /*   padding: 3px;


  margin: 0;
  margin-right: 5px;

 */
  width: 25px;
  height: 25px;
  background-color: #4db6ac;
  border-radius: 50%;
  display: flex; /* Use flexbox */
  align-items: center; /* Center vertically */
  justify-content: center;
  margin-right: 5px;
}

.iconContainer svg {
  width: 18px;
  height: 18px;
  fill: #ffffff;
}

/*  @media(max-width: 1000px) {
  .list {
    left: 20px !important;
  }
}  */
@media (max-width:769px) {

  .btn,
  .activeBtn {
    font-size: 10px;

  }

  .iconContainer {
    /*   padding: 3px;
  
  
    margin: 0;
    margin-right: 5px;
  
   */
    width: 10px;
    height: 10px;
    background-color: #4db6ac;
    border-radius: 50%;
    padding-top: 1px;
    padding-left: 0.5px;
  }

  .iconContainer svg {
    width: 9px;
    height: 9px;

    fill: #ffffff;
  }

  .list {
    margin-left: 25px;
  }
}

/* 
@media(min-width: 1940px) {
  .list {
    margin-right: 90px;
  }
} */