.link {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #30ad64;
  fill: #30ad64;
  height: 100%;
  border: none;
  background-color: inherit;
  font-weight: 600;
}

.link svg {
  margin-left: 5px;
  width: 15px;
  height: 13px;
}

.link:hover,
.link:hover svg {
  color: #9ec6af;
  fill: #9ec6af;
}
