.up {
  border: solid #4DB6AC;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  border: solid #4DB6AC;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.btnSubmit {
  position: relative;
  top: -6px;
  /* left: -25%; */
  float: right;
  cursor: pointer;

  background-color: #ffffff;
  border: none;
  border-radius: 4px;

  color: #4DB6AC;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
  padding: 0px;
}
