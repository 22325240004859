.custom_dialog {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Styling for the close button */
.custom_dialog_close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.removeButton {
  margin-top: 20px;
  /*  padding: 10px 20px; */
  border: none;
  border-radius: 4px;
  background-color: #4db6ac;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.removeButton:hover {
  background-color: #0056b3;
}

.removeButton:active {
  background-color: #003d80;
}

.removeButton:not(:last-child) {
  margin-right: 10px;
}