.container {
  margin: 0;
  padding-top: 1px;
  padding-right: 0;
  padding-bottom: 7,5px;
  padding-left: 0;
/*   height: 50px; */
  width: 100%;
  min-width: 320px;

  background-color: #4db6ac;
  /* display: flex; */
  justify-content: space-between;
}

.block {
  margin: 0 10px;
  padding: 0;

  min-width: 280px;
}
@media (max-width: 940px) {
  .container {
    /* padding-top: 20px; */
    padding-bottom: 0px;
  } 
  .block {
   
  }
}
@media(min-width: 768px) {
 /*  .container {
    padding-top: 20px;
    padding-bottom: 20px;
  } */

  .block {
    display: flex;
    justify-content: space-between;
    align-items:center;
    margin-right: auto;
    margin-left: auto;

    /* width: calc(728px + (100vw - 768px) / 3.16); */
  }
}
@media (min-width:1000px) {
  .block {
    display: flex;
    justify-content: space-between;
    align-items:center;
    margin:0px;
    margin-left:20px;

   /*  width: calc(728px + (100vw - 768px) / 3.16); */
  }
}

@media(min-width: 2000px) {
  .block {
    margin: 0 ;
    margin-left: 20px;
 /* width: calc(728px + (100vw - 2000px) / 3.16); */
   /*   width: 2020px */; 
   /* width: auto; */
  }
 /*  @media(min-width: 2000px) {
    .block {
      margin-right:auto;
      margin-left: auto;
    }
  } */
}
