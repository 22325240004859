.paramsItem {
    display: grid;
    grid-template-columns: auto 1fr 200px;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    width: 100%;
  }
  
  .paramsName {
    margin: 0;
    padding: 0;
  }
  
  .paramsDecor {
    display: block;
  
    width: 100%;
    height: 100%;
  
    border-bottom: 3px dotted #707070;
  }
  
  .paramsValue {
    margin: 0;
    padding: 0;
    width: 270px;
  }

  .highLighted {
    background: yellow;
    border: 1px solid black;
    border-radius: 10%;
    padding: 3px;
  }