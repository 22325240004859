.commonValues {
  grid-area: commonValues;
  /* padding-top: 10px; */
  padding-right: 10px;
  padding-left: 0px;
  margin-left: 0%;
  white-space: nowrap;
  border-top:  1px solid #4DB6AC;
 
  display: grid;
  grid-template-columns: 280px;
}
@media all and (min-width: 1222px) {
  .commonValues {
      grid-template-columns: minmax(280px, 50%) minmax(280px, 50%);
    grid-template-areas: 'left right';
  }
  }
  .procedure
  {
    border-right: 1px solid #4DB6AC;
  }
.commonLabel {
  display: flex;
  justify-content: flex-end;
 /*  font-size: 1vmax; */
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
  padding-left: 0px;
}
.commonLabel:nth-child(3){
  
  border: none;
  width: 275px;
  border-bottom: 1px solid rgba(112,112,112, 0.5);
  margin-bottom: 20px;
  margin-right: 0px;
  padding-bottom: 20px;
  padding-right: 0px;
}
@media (max-width:1220px) {
  .commonLabel:nth-child(3){
    width: 260px;
  }
}
.commonInput {
  margin: 0;
  margin-left: 5px;
  padding: 0;
  
  text-align-last:center;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-left: none;
  color: #707070;
  font-weight: 600;

  width: 110px;
}
select{
  border-color: red;
}
.commonInput::placeholder {
  color:rgba(77, 182, 172, .5);
  
}
.extraContainer {
  margin: 0;
  padding: 0 5px 0 5px ;
  border-top: 0;
  border-right: 1px solid  #4DB6AC;
  border-bottom: 0;
  border-left: 0;
}
@media (max-width:956px) {
  .extraContainer{
   padding: 0;
  }
}

.checkboxContainer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  margin: 0;
  margin-right: 20px;
  margin-top: 20px;
  margin-left: auto;
  padding: 0;

  width: 180px;
  border:none;
  border-top:  1px solid rgba(112,112,112, 0.5);
  border-bottom:  1px solid rgba(112,112,112, 0.5);
}

.checkLabel {
  margin: 0;
  padding: 0;
}

.checkLabel:first-child {
  margin-top: 20px;
  margin-right: 10px;
}
.checkLabel:nth-child(2) {
  margin-top: 20px;
}

.checkbox {
  margin: 0;
  margin-bottom: 20px;
  height: 20px;
  width: 20px;
  margin-left: 5px;
  padding: 0;
  border-radius: 0%;
}
.checkbox:checked:before{
  background-color:green;
  }
.extraStop {
  margin: 0;
  margin-top: 5px;
  padding: 0;
  border: none;
}

.extraTitle {
  margin: 0;
  padding: 0;

  font-size: 50px;
  font-weight: bold;
}

.extraBtn {
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left:auto;
  padding: 0;
  padding-right:10px;
  border:none;
  border-top:1px solid #4DB6AC;
  border-bottom: 1px solid #4DB6AC;
  border-top-left-radius: 20px 20px;
  border-bottom-left-radius: 22px 20px;
  background-color: inherit;
  color:#707070 ;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}
.extraBtnAdd {
  font-size: 20px;
  display: flex;
  align-items:center;
  margin-top: 10px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left:auto;
  padding: 0;
  padding-right:83px;
  padding-left: 0px;
  border:none;
  border-top:1px solid #4DB6AC;
  border-bottom: 1px solid #4DB6AC;
  border-top-left-radius: 20px 20px;
  border-bottom-left-radius: 22px 20px;
  background-color: inherit;
  color:#707070 ;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}
.extraBtnAdd svg {
  margin-left: 0px;
  margin-right: 8px;
  width: 28px;
  height: 28px;
  fill: #4db6ac;
}

.extraBtn svg {
  margin-left: 0px;
  margin-right: 7px;
  width: 28px;
  height: 28px;
  fill: #4db6ac;
}
.extraBtn1 {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}
.extraBtn1 svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: #4db6ac;
}
.removeExtraBtn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;
  border: none;
  background-color: inherit;
  
}

.removeExtraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: red;
}
.buttonContainer{
  border:0;
  border-bottom: 1px solid #4DB6AC;
}
.greedcontainer{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: "leftCommon procedure";
}