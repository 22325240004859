.container {
  grid-area: resultBox;
  margin: 0;
  padding-top: 10px;
  padding-right: 0;
  padding-bottom: 0;
 /*  padding-left: 30px; */
}

.title {
  margin: 0;
  padding: 0;
  /* width:670px; */
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.selectedInfo {
  display: flex; 
 margin-top: 10px;
 margin-right: auto;
 margin-left: auto;
 margin-bottom: 10px;
 padding: 0;
 align-items: center;
 justify-content: center
}
.departmentName {
  margin: 0 20px 0 0;

  color: #4db6ac;
  font-size: 16px;
  text-transform: capitalize;
}

.rateName {
  margin: 0;
  padding: 0;
  color: red;
  font-size: 16px;
  text-transform: capitalize;
}

.btnSubmit {
  position: relative;
  top: -30px;
  float: right;
  padding-top: 10px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-left: 30px;

  cursor: pointer;

  background-color: #30ad64;
  border: none;
  border-radius: 4px;

  color: #ffffff;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
}

.oldApplyNew {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calculationNumber {
  margin: 0 20px 0 0;

  color: #707070;
  font-size: 16px;
  text-transform: capitalize;
  /* width:400px; */
  text-align: center;
}

