.title {
  margin-top: -15px;
  margin-right: 0;
  margin-bottom: 30px;
  margin-left: 0;
  padding: 0;

  color: #30ad64;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
}

.form {
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
  padding: 0;

  width: 240px;
}

.field {
  display: block;
  margin: 0;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-left: 15px;

  width: 100%;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;

  color: #30ad64;
  font-size: 16px;
}

.field:last-child {
  margin-bottom: 0;
}

.field::placeholder {
  color: #4db6ac;
}

@media(min-width: 768px) {
  .form {
    width: 300px;
  }

  .field {
    margin-bottom: 30px;
  }
}
