.copy_icon {
    font-size: 24px; /* Adjust the font size as needed */
    opacity: 0;
    color: white; /* Initial color */
    cursor: pointer;
    transition: opacity 0.3s; /* Add a smooth color transition */
}

/* Change the color when hovering */
.copy_icon:hover {
    color: #707070; /* Color when hovered */
    opacity: 1;
}

.icon {
  height: 20px;
  margin-left: 10px;
}