.form {
  margin: 0 auto;
  padding: 0;
  padding-bottom: 70px;

  width: 940px;

  /*   overflow: auto; */
}

.formHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0;
  padding-top: 30px;
  padding-right: 0;
  padding-bottom: 25px;
  padding-left: 0;

  border: none;
}

.fieldName {
  margin: 0;
  margin-right: 25px;
  padding: 0;

  color: #676770;
  font-size: 16px;
}

.departmentField {
  margin: 0;
  padding-left: 50px;

  width: 150px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #676770;
  border-left: none;
}

.calcMethContainer {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.departmentField::placeholder {
  color: rgba(77, 182, 172, .5);
  font-size: 16px;
}

.inputGroup {
  margin: 0;
  padding: 0;

  border-top: 1px dotted #676770;
  border-right: none;
  border-bottom: none;
  border-left: none;
}

.rateName {
  margin: 0;
  margin-bottom: 25px;
  margin-left: 30px;
  padding: 0 10px;

  color: 676770;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}

.rateSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

  width: 383px;
}

.inputName {
  margin: 0;
  margin-right: 5px;
  padding: 0;
/* 
  width: 95px; */

  color: #676770;
  font-size: 16px;
  text-align: right;
}
.inputName1{
  margin: 0;
  margin-right: 5px;
  padding: 0;
 
  width: 95px; 

  color: #676770;
  font-size: 16px;
  text-align: right;

}

.input {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 20px;

  width: 200px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInput {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 30px;

  width: 90px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.doubleInputSecond {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 45px;

  width: 120px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.input::placeholder,
.doubleInput::placeholder,
.doubleInputSecond::placeholder {
  margin: 0;
  padding: 0;

  color: rgba(77, 182, 172, .5);
  font-size: 16px;
}

.extInput::placeholder {
  margin: 0;
  padding: 0;
}

.inputValueType {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 182px;
  top: center;
}

.extInputValueType {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 5px;
  top: center;
}

.inputValueTypeSecond {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 265px;
  top: center;
}

.radiobtnContainer {
  display: flex;
  margin: 0;
  padding: 0;
}

.shuttleContainer {
  display: flex;
  justify-content: space-between;
}

.shuttleContainer .inputContainer {
  width: fit-content;
}

.directionName {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 50px;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.shuttleContainer {
  display: flex;
  justify-content: space-between;
}

.shuttleContainer .optionsContainer {
  width: fit-content;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

  width: 320px;
}

.inputName {
  margin: 0;
  margin-right: 5px;
  padding: 0;

 /*  width: 173px; */

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.input {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 25px;

  width: 130px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.extInput {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 15px;
  margin-left: 5px;

  width: 90px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.inputContainerSecond {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;

  width: 162px;
}

.inputNameSecond {
  margin: 0;
  margin-right: 5px;
  padding: 0;

  width: 55px;

  color: #676770;
  font-size: 16px;
  text-align: right;
}

.thirdText {
  margin: 0;
  margin-right: 5px;
  padding: 0;
  width: 70px;
  color: #676770;
  font-size: 16px;
  text-align: right;
}

.inputSecond {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 25px;

  width: 100px;

  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
}

.inputSecond::placeholder {
  color: rgba(77, 182, 172, .5);
}

.inputValueType {
  position: absolute;
  z-index: 10;
  margin: 0;
  padding: 0;

  font-size: 16px;
  color: #676770;

  left: 65px;
  top: center;
}

.optionsContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
}

.radiobtnContainer {
  margin: 0;
  padding: 0;
}

.chargeButtonContainer {
  width: 150px;
}

.extraBtn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.extraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: #4db6ac;
}

.removeExtraBtn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 0;

  border: none;
  background-color: inherit;
}

.removeExtraBtn svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  fill: red;
}

.buttonContainer {
  display: inline-block;
}

/* .cashPaymentContainer {
  position: relative;
  left: 35px;
} */

/* .parametersContainer {
  margin-top: 25px;
} */

.extContainer {
  left: 50px;
  position: relative;
}

.extInputTitle {
  color: #676770;
  font-size: 16px;
}
.distanceContainer {
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  /*  width: 776px; */
}
.allPage
{
  padding: 20px 50px;
  border: 2px solid rgba(77, 182, 172, .5)
}