.container {
  min-width: 320px;
}

.list {
  margin: 0 auto;
  padding: 15px 20px;
  width: 100%;
  max-width: 290px;
  list-style: none;
/*   align-items: center; */
  justify-content: center;

}

.item {
  margin-bottom: 15px;
  
}

/* .item:last-child {
  margin-bottom: 0;
} */

.btn,
.activeBtn {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 5px 10px;

  border: 1px solid #30ad64;
  border-radius: 5px;

  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.btn {
  color: #676770;
  background-color: inherit;
  cursor: pointer;
}

.btn:hover {
  color: #ffffff;
  background-color: #4db6ac;
}

.activeBtn {
  color: #ffffff;
  background-color: #4db6ac;
}

.iconContainer {
  margin: 0;
  margin-right: 5px;
  padding: 4px;

  width: 20px;
  height: 20px;

  background-color: #4db6ac;
  border-radius: 50%;
}

.iconContainer svg {
  width: 12px;
  height: 12px;

  fill: #ffffff;
}

.iconContainer img {
  width: 20px;
  height: 20px;

  fill: #ffffff;
}

@media(min-width: 500px) {
  .list {
    display: flex-column;
    margin: 0 auto;
    text-align: center;
  }

  .item {
    margin-right: 20px;
  }
}

@media(min-width: 768px) {
  .list {
    padding-right: 0;
    padding-left: 0;
  }

  .btn,
  .activeBtn {
    padding-right: 50px;
    font-size: 18px;
  }

  .iconContainer {
   /*  padding: 6px; */
    width: 30px;
    height: 30px;
  }

  .iconContainer svg {
    width: 18px;
    height: 18px;
  }
}

@media(min-width: 1440px) {
  .list {
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;

    width: 940px;
  }
}
