.container {
    display: flex;
    justify-content: center;
    margin: 0;
    padding-top: 10px;
    padding-right: 0;
    padding-bottom: 15px;
    padding-left: 0;

    width: 100%;
    min-width: 320px;

    background-color: #4db6ac;
}

.titleContainer {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 100px;
}

.block {
    margin: 0 20px;
    padding: 0;

    min-width: 280px;
}

.errorNumber {
    font-size: calc(75px + (100vw - 320px) / 40.72);
    margin-bottom: 0px;
    font-weight: normal;
}

.errorTitle {
    font-size: calc(14px + (100vw - 320px) / 40.72);
    font-weight: normal;
}