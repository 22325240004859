.btnSubmit {
    position: relative;

    float: right;
    padding: 5px 25px 5px 10px;
    cursor: pointer;

    background-color: #30ad64;
    border: none;
    border-radius: 4px;

    color: #ffffff;
    font-size: 16px;
    letter-spacing: 2px;
    text-align: center;
    z-index: 3;
    width: 10px;
}

i {
    position: absolute;
    top: 10px;
    right: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotateZ(45deg);
    transform-origin: center;
    transition: 0.3s;
}

i.up {
    transform: rotateZ(225deg) translateY(-25%);
}

.blockHead {
    display: flex;
    align-items: center;
}



.btnReset {
    display: block;
  
  
    width: 60px;
  

  
    cursor: pointer;
  
    background-color: #4DB6AC;
    border: none;
    border-radius: 4px;
  
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 1px;
    text-align: center;
    padding: 3px;
    margin-bottom: 5px;
  }
  
  .btnReset:disabled {
    background-color: rgba(128, 128, 128, 0.5);
  }
  
  .btnReset:disabled:hover {
    cursor: not-allowed;
    box-shadow: none;
  }
  
  