.inputContainer {
  position: relative;
  display: flex;
  /* align-items: center; */
  margin: 0;
  margin-top: 10px;
  /* padding-bottom: 20px; */
  padding: 0;

  
}

.inputName {
  margin: 0;
  margin-left: 5px;
  margin-right: 6px;
  padding: 0;
  padding-bottom: 15px;


  color: #676770;
  font-size: 16px;
  text-align: right;
}

.radiobtnContainer {
  
  margin: 0;
  padding: 0;
}
.radio{
  margin-left: 15px;
}
