.container {
/*   margin-top: 20px; */

  width: 100%;
}

@media(min-width: 768px) {
  .container {
    margin-right: auto;
    margin-left: auto;

    width: 100%;
  }
}
