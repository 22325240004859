.paramsSection {
  margin: 0;
  padding: 0;

  width: 700px;

  list-style: none;

  border-bottom: 1px solid rgba(112,112,112, 0.5);
}

.paramsItem {
  display: grid;
  grid-template-columns: auto 1fr 200px;

  margin: 0;
  margin-bottom: 10px;
  padding: 0;

  width: 100%;
}

.paramsName {
  margin: 0;
  padding: 0;
}

.paramsDecor {
  display: block;

  width: 100%;
  height: 100%;

  border-bottom: 3px dotted #707070;
}

.paramsValue {
  margin: 0;
  padding: 0;
}


.btnSubmit {
  position: relative;
  top: -20px;
  left: -15.8%;
  float: right;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;

  cursor: pointer;

  background-color:#ffffff ;
  border: none;
  border-radius: 4px;

  color: #4DB6AC;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
}
 /* @media (max-width:1370px) {
  .btnSubmit{
    padding-right: 150px;
  }
  
 } */

.up {
    border: solid #4DB6AC;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
    border: solid #4DB6AC;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


.titleCenter {
  text-align: center;

  margin: 0;
  padding: 0;

  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.mycheckbox {
  padding: 0 !important;
  width: 25px !important;
  height: 18px;
  margin: 0 !important;
  margin-top: 5px !important;
}